import type { ExtraDataObj, ServerPaginatedTable } from '@/types/mercury-data-types';

export interface TableData<TData> {
  tableData: TData;
  pageCount?: number;
  extra?: ExtraDataObj;
}

export const serverTableNormalize = <TData extends ServerPaginatedTable<Array<unknown>>>(
  data: TData,
): TableData<TData['data']> => ({
    tableData: data.data,
    pageCount: data.recordsFiltered,
    extra: data.extra,
  });

export const basicTableTransform = <TData extends Array<unknown>>(
  data: TData,
): TableData<TData> => ({
    tableData: data,
    pageCount: undefined,
    extra: {},
  });
