import { Flex, Stack, TypographyStylesProvider } from '@mantine/core';

// Components
import Button from '../button/Button';
import Modal from '../modal/Modal';

// Types
import type { ConfirmationProps } from './types';

// Misc
import { sanitize } from '@/utils/sanitize';

const ConfirmationDialog = ({
  title,
  text,
  confirmText,
  open,
  onConfirm,
  confirmColor,
  onDismiss,
  useHtml,
  disableCancel,
}: ConfirmationProps) => (open ? (
  <Modal onClose={onDismiss} size="500px" opened closeOnEscape={false} withCloseButton={false} centered>
    <Stack gap={25}>
      <Stack gap={10} align="center">
        <h1>{title}</h1>

        {text && !useHtml && <p>{text}</p>}
        {text && useHtml && <TypographyStylesProvider dangerouslySetInnerHTML={{ __html: sanitize(text) }} />}
      </Stack>

      <Flex
        gap={15}
        direction={{ base: 'column', md: 'row' }}
        justify="center"
      >
        <Button color={confirmColor} onClick={onConfirm}>
          {confirmText}
        </Button>

        {!disableCancel && (
          <Button
            variant="outline"
            onClick={onDismiss}
          >
            Cancel
          </Button>
        )}
      </Flex>
    </Stack>
  </Modal>
) : null);

export default ConfirmationDialog;
