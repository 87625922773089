import { forwardRef } from 'react';
import { Box, BoxProps, createPolymorphicComponent, Tooltip } from '@mantine/core';

import useValueMap from '@/hooks/useValueMap';

import type { PillsBaseProps } from './types';

import classes from './Pills.module.css';

type PillProps = {
  value: string;
  highlight?: string;
  full?: boolean;
  transform?: (value: string) => string;
} & PillsBaseProps & BoxProps;

const Pill = createPolymorphicComponent<'li', PillProps>(
  forwardRef<HTMLLIElement, PillProps>((
    {
      color,
      value,
      pillProps,
      size = 'small',
      highlight,
      full,
      transform,
      ...elementProps
    },
    ref,
  ) => {
    if (!value) return false;

    const pill = useValueMap(value, pillProps, { bg: 'var(--item-bg)', color: color ?? 'inherit' });

    return (
      <Tooltip label={pill.tooltip} disabled={!pill.tooltip}>
        <Box
          className={`
            ${classes.pill}
            ${size === 'big' ? classes.big : ''}
            ${highlight ? classes.highlight : ''}
            ${full ? classes.full : ''}
          `}
          component="li"
          ref={ref}
          style={{
            '--pill-bg': pill.bg ?? 'var(--item-bg)',
            '--pill-font-color': pill.color ?? color ?? 'inherit',
            '--pill-highlight': highlight || 'transparent',
          }}
          {...elementProps}
        >
          {transform ? transform(value) : value}
        </Box>
      </Tooltip>
    );
  }),
);

export default Pill;
