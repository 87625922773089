import { MercuryTrackersKeys } from '@/constants/mercuryPages';

export const incidentsKeys = {
  all: ['incidents'] as const,

  table: (type: string) => [...incidentsKeys.all, 'table', type] as const,

  meta: (type: string) => [...incidentsKeys.all, 'meta', type] as const,

  gfx: (type: string) => [...incidentsKeys.all, 'gfx', type] as const,

  issue: (issueId: number) => [...incidentsKeys.all, 'issue', issueId] as const,

  summary: () => [...incidentsKeys.all, 'summary'] as const,

  alerts: MercuryTrackersKeys.alerts,

  takedowns: MercuryTrackersKeys.takedowns,
};
