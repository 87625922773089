import { useContext } from 'react';

import { ToastContext } from '@/context/ToastContext';

export const useToast = () => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error('ToastContext is not initialized. This hook must be used inside a ToastContextProvider');
  }

  return toastContext;
};
