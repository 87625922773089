import { ActionIcon, TextInput, TextInputProps } from '@mantine/core';

import SearchIcon from '@/assets/icons/content/search.svg';

import classes from './SearchBar.module.css';

interface SearchBarProps extends TextInputProps {
  onSearch?: VoidFunction;
  search?: string;
}

const SearchBar = ({
  onSearch,
  search,
  ...inputProps
}: SearchBarProps) => (
  <TextInput
    classNames={classes}
    value={search}
    role="searchbox"
    rightSection={(
      <ActionIcon
        variant="subtle"
        onClick={() => onSearch?.()}
        aria-label="Search"
      >
        <SearchIcon />
      </ActionIcon>
    )}
    {...inputProps}
  />
);

export default SearchBar;
