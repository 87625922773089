import { useMediaQuery } from '@mantine/hooks';

import Desktop from './NavBarSearchBar';
import Mobile from './Mobile';

const SearchBar = () => {
  const matches = useMediaQuery('(min-width: 62em)');

  if (matches) {
    return <Desktop size="xs" />;
  }

  return (
    <Mobile />
  );
};
export default SearchBar;
