import { useEffect } from 'react';
import { TypographyStylesProvider } from '@mantine/core';
import { useCountDown } from 'ahooks';

import MercuryButton from '@/components/content/button/Button';

import { useRateLimit } from '@/context/RateLimitContext';
import { useModal } from '@/hooks/useModal';
import { useQueryActions } from '@/hooks/useQueryActions';

const ModalContent = ({ retryAfter, retryCb }: { retryAfter: number; retryCb: VoidFunction }) => {
  const [countdown, formattedRes] = useCountDown({ leftTime: retryAfter });
  const { refetchQueries } = useQueryActions();

  const { hours, minutes, seconds } = formattedRes;

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  const time = `${formatTime(hours)}h ${formatTime(minutes)}m ${formatTime(seconds)}s`;

  const onRetry = () => {
    if (countdown > 0) return;

    retryCb();
    refetchQueries({ stale: true });
  };

  return (
    <TypographyStylesProvider>
      <p>It seems like you have sent too many request to us recently. Please try again later.</p>

      <MercuryButton fullWidth mt={25} disabled={countdown > 0} onClick={onRetry}>
        {countdown ? `Retry in ${time}` : 'Retry now'}
      </MercuryButton>
    </TypographyStylesProvider>
  );
};

const RateLimit = () => {
  const { Modal, modalProps, closeModal, openModal } = useModal();
  const { retryAfter, disableRateLimit } = useRateLimit();

  useEffect(() => {
    if (retryAfter) {
      openModal();
    }
  }, [retryAfter]);

  const disableModal = () => {
    disableRateLimit();
    closeModal();
  };

  return (
    <Modal
      {...modalProps}
      overlayProps={{ blur: 8 }}
      title="Rate Limit Exceeded"
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}

    >
      <ModalContent retryAfter={retryAfter} retryCb={disableModal} />
    </Modal>
  );
};

export default RateLimit;
