import type { PropsWithChildren, ReactNode } from 'react';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import type { AccessModules, AccessRoles } from '@/types/permissions';
import { Navigate } from 'react-router-dom';

export interface PermissionsProps {
  requiredModules?: AccessModules;
  allowedRoles?: AccessRoles;
  redirect?: string;
  state?: Record<string, unknown>;
  noAccessRender?: ReactNode;
  remove?: boolean;
  customAccessCheck?: ({ modules, roles }: { modules: any, roles: any }) => boolean;
}

const Permission = ({
  children,
  requiredModules,
  allowedRoles,
  redirect,
  state,
  noAccessRender,
  remove,
  customAccessCheck,
}: PropsWithChildren<PermissionsProps>) => {
  const { hasAccess } = useMercuryPermissions();
  const canAccess = customAccessCheck
    ? customAccessCheck({ modules: requiredModules, roles: allowedRoles })
    : hasAccess({ modules: requiredModules, roles: allowedRoles });

  if (canAccess) {
    return <>{children}</>;
  }

  if (noAccessRender) {
    return <>{noAccessRender}</>;
  }

  if (remove) {
    return null;
  }

  return <Navigate to={redirect || '/'} state={state} />;
};

export default Permission;
