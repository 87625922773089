import type { News } from '../types';

export const hasUnreadItems = (news: News) => {
  const forceHide = window.localStorage.getItem('isPlaywright');
  const lastRead = localStorage.getItem('news.lastRead');

  return !forceHide && news && news.date !== lastRead;
};

export const setReadItems = (news: News) => {
  localStorage.setItem('news.lastRead', news && news.date);
};
