// Hooks
import { useTFAMutation } from '../services/tfa';

// Types
import type { GeneralErrorFunc, OnSubmitFunc } from '@/types/form';
import type { TFAFormFields } from '../types';
import type { MutationOptions } from '@/types/react-query';

export type TFAData = {
  uri: string;
  secret: string;
};

interface UseTFAProps {
  onActivateSuccess: (tfa: boolean) => void;
  onDeactivateSuccess?: (tfa: boolean) => void;
  onGenerateSuccess?: VoidFunction;
  onGenerateError?: VoidFunction;
  username: string;
}

type GenerateTFAData = { username: string };

export const useTfa = ({
  onGenerateSuccess,
  onGenerateError,
  onActivateSuccess,
  onDeactivateSuccess,
  username,
}: UseTFAProps) => {
  const {
    data: TFAData,
    isPending: generating,
    isError: isGenerationError,
    mutate: generate,
  } = useTFAMutation<TFAData, GenerateTFAData>('generate');
  const {
    isPending: activating,
    mutate: activate,
  } = useTFAMutation<TFAFormFields, TFAFormFields & GenerateTFAData>('activate');
  const {
    isPending: deactivating,
    mutate: deactivate,
  } = useTFAMutation<TFAFormFields, TFAFormFields & GenerateTFAData>('deactivate');

  const mutationOptions = (
    onSuccess: ((tfa: boolean) => void) | undefined,
    tfa: boolean,
    onError: GeneralErrorFunc,
  ): MutationOptions<TFAFormFields> => ({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess(tfa);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  const activateTFA: OnSubmitFunc<TFAFormFields> = (formData, setGeneralError) => {
    activate(
      { ...formData, username },
      mutationOptions(onActivateSuccess, true, setGeneralError),
    );
  };

  const deactivateTFA: OnSubmitFunc<TFAFormFields> = (formData, setGeneralError) => {
    deactivate(
      { ...formData, username },
      mutationOptions(onDeactivateSuccess, false, setGeneralError),
    );
  };

  const generateTFA = () => {
    generate({ username }, {
      onSuccess: () => {
        if (onGenerateSuccess) onGenerateSuccess();
      },
      onError: () => {
        if (onGenerateError) onGenerateError();
      },
    });
  };

  const disableButton = generating || deactivating || activating;

  return {
    generateTFA,
    activateTFA,
    deactivateTFA,
    TFAData,
    disableButton,
    isGenerationError,
  };
};
