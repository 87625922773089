import { MercuryTrackersKeys } from '@/constants/mercuryPages';
import type { IntTrackers } from '../types';

export const intelligenceKeys = {
  all: ['intelligence'] as const,

  table: (type: string) => [...intelligenceKeys.all, 'table', type] as const,

  meta: (type: string) => [...intelligenceKeys.all, 'meta', type] as const,

  gfx: (type: IntTrackers) => [...intelligenceKeys.all, 'gfx', type] as const,

  issue: (issueId: number) => [...intelligenceKeys.all, 'issue', issueId],

  finished: MercuryTrackersKeys.finished,

  raw: MercuryTrackersKeys.raw,
};
