import { type PropsWithChildren, type Ref, forwardRef, ElementType } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import Linkv2, { LinkV2Props } from './Linkv2';

type RouterLinkProps<C extends ElementType = 'a'> = {} & LinkProps & LinkV2Props<C>;

const RouterLink = forwardRef((
  { to, state, children, ...linkProps }: PropsWithChildren<RouterLinkProps>,
  ref: Ref<HTMLAnchorElement>,
) => (
  <Linkv2 as={Link} to={to} state={state} ref={ref} {...linkProps}>{children}</Linkv2>
));

export default RouterLink;
