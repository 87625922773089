import type { PropsWithChildren, ReactNode } from 'react';

interface WrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => any;
}

const ConditionalWrapper = ({ children, condition, wrapper }: PropsWithChildren<WrapperProps>) => (
  condition ? wrapper(children) : children
);

export default ConditionalWrapper;
