import { Group } from '@mantine/core';
import React from 'react';
import {
  AriaDateFieldProps, DateValue, useDateField, useDateSegment, useLocale,
} from 'react-aria';
import { type DateFieldState, type DateSegment, useDateFieldState } from 'react-stately';

import { createCalendar } from './createCalendar';

function getDateFieldSegments(segments: Array<DateSegment>): DateSegment[] {
  const daySegment = segments.find((segment) => segment.type === 'day');
  const monthSegment = segments.find((segment) => segment.type === 'month');
  const yearSegment = segments.find((segment) => segment.type === 'year');
  const separatorSegment = segments.find(
    (segment) => segment.type === 'literal',
  );

  if (!daySegment || !monthSegment || !yearSegment || !separatorSegment) {
    throw new Error('Invalid date field segments');
  }

  const formattedDaySegment = {
    ...daySegment,
    text: daySegment.text.padStart(2, '0'),
  };
  const formattedMonthSegment = {
    ...monthSegment,
    text: monthSegment.text.padStart(2, '0'),
  };
  const formattedSeparatorSegment = { ...separatorSegment, text: '-' };

  return [
    yearSegment,
    formattedSeparatorSegment,
    formattedMonthSegment,
    formattedSeparatorSegment,
    formattedDaySegment,
  ];
}

const DateSegmentComp = ({ segment, state }: { segment: DateSegment, state: DateFieldState }) => {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div {...segmentProps} ref={ref} className={`segment ${segment.isPlaceholder ? 'placeholder' : ''}`}>
      {segment.text}
    </div>
  );
};

const DateField = ({ label, ...rest }: AriaDateFieldProps<DateValue>) => {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...rest,
    locale,
    createCalendar,
  });

  const segments = getDateFieldSegments(state.segments);

  const ref = React.useRef(null);
  const { labelProps, fieldProps } = useDateField(rest, state, ref);

  return (
    <>
      {label && <span {...labelProps}>{label}</span>}
      <Group {...fieldProps} ref={ref} gap={0} wrap="nowrap">
        {segments.map((segment, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DateSegmentComp key={`${rest['aria-labelledby']}-segment-${i}`} segment={segment} state={state} />
        ))}
      </Group>
    </>
  );
};

export default DateField;
