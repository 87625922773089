export type PasswordChangeFields = {
  currentPassword: string;
  newPassword: string;
  retypeNewPassword: string;
};

export const PasswordChangeLabels = {
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  retypeNewPassword: 'Retype New Password',
} as const;

export type TFAFormFields = { code: string; };
export const TFAFormLabels = { code: 'Enter the code from your Two-Factor app' } as const;

const TRANSACTION_STATUSES = {
  FEEDBACK: 'Feedback',
  REJECTED: 'Rejected',
  CLOSED: 'Closed',
  RESOLVED: 'Resolved',
  APPROVED: 'Approved for Production',
} as const;

type TransactionStatus = typeof TRANSACTION_STATUSES[keyof typeof TRANSACTION_STATUSES];

export interface Transactions {
  issue_id: number;
  tracker_id: number;
  reference: string;
  created_on: string;
  referer: string;
  status: TransactionStatus;
  credit: number;
  debit: number;
}
