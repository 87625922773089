import type { PropsWithChildren } from 'react';
import { Tooltip } from '@mantine/core';

import classes from './DotIndicator.module.css';

interface DotIndicatorProps {
  display: boolean;
  tooltip: string;
  animated?: boolean;
}

const DotIndicator = ({
  display,
  tooltip,
  animated,
  children,
}: PropsWithChildren<DotIndicatorProps>) => {
  if (!display) return children;

  return (
    <Tooltip label={tooltip}>
      <div className={classes.wrapper}>
        <span className={`${classes.icon} ${animated ? classes.animated : ''}`} />
        {children}
      </div>
    </Tooltip>
  );
};

export default DotIndicator;
