export const unifiedApiKeys = {
  all: ['unified-api'],

  records: (endpoint: string, params?: Record<string, any>) => (
    [...unifiedApiKeys.all, endpoint, ...(params ? [params] : [])]
  ) as const,

  summary: (endpoint: string, params?: Record<string, any>) => (
    [...unifiedApiKeys.records(endpoint, params), 'summary']
  ) as const,

  ticket: (endpoint: string, id: number) => (
    [...unifiedApiKeys.records(endpoint), 'ticket', id]
  ) as const,

  attachments: (endpoint: string, id: number) => (
    [...unifiedApiKeys.ticket(endpoint, id), 'attachments']
  ) as const,
};
