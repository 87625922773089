import { Flex, Tooltip } from '@mantine/core';

import DocumentationIcon from '@/assets/icons/sidebar/documentation.svg';

import classes from './Navbar.module.css';

const Documentation = ({ desktopOpened }: { desktopOpened: boolean }) => (
  <Flex
    align="center"
    wrap="nowrap"
    component="a"
    gap="md"
    href="/docs/"
    target="_blank"
    aria-label="API Documentation"
  >
    <Tooltip
      arrowOffset={10}
      arrowSize={4}
      label="API Documentation"
      position="right"
      offset={40}
      color="var(--active-color)"
      disabled={desktopOpened}
      visibleFrom="sm"
      transitionProps={{ duration: 0 }}
      withArrow
    >
      <span className={classes.utilityIcon}>
        <DocumentationIcon width={20} height={20} />
      </span>
    </Tooltip>

    <span className={`${classes.toggleLabel} ${desktopOpened ? classes.expanded : ''}`}>
      API Documentation
    </span>
  </Flex>
);

export default Documentation;
