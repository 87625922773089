export const toDate = (value: string | Date | null, options?: Intl.DateTimeFormatOptions) => (
  value ? new Date(value).toLocaleDateString('en-GB', { timeZone: 'UTC', ...options }) : ''
);

const toTime = (value: string, options?: Intl.DateTimeFormatOptions) => (
  value ? new Date(value).toLocaleTimeString('en-GB', options) : ''
);

export const toDateTime = (value: string, options?: Intl.DateTimeFormatOptions) => (
  value ? `${toDate(value, options)} ${toTime(value, options)}` : ''
);

export const toIsoDate = (value: string | Date) => {
  const date = new Date(value);

  const pad = (num: number) => num.toString().padStart(2, '0');

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const formatIsoDate = (value: string, removeTime: boolean = false) => {
  if (!value) return value;

  const date = value.split('T');

  if (removeTime) {
    return date[0];
  }

  return `${date[0]} ${date[1].split('.')[0]} `;
};
