import { Box } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './FlexibleGridBox.module.css';

type GridCount = {
  S: number;
  M: number;
  L: number;
};

const FlexibleGridBox = ({ count, children }: PropsWithChildren<{ count: GridCount }>) => (
  <Box
    className={classes.grid}
    style={{
      '--grid-column-count-S': count.S,
      '--grid-column-count-M': count.M,
      '--grid-column-count-L': count.L,
    }}
  >
    {children}
  </Box>
);

export default FlexibleGridBox;
