import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchData } from '../fetch';

// Types
import type { FetchDataArguments, PostPutMethod, FetchError } from '@/types/api';

interface MutationArguments<TData, TVariables> {
  endpoint: string;
  method?: PostPutMethod;
  headers?: HeadersInit;
  requestOptions?: Partial<FetchDataArguments & { contentType?: string }>;
  mutationOptions?: UseMutationOptions<TData, FetchError, TVariables>;
  json?: boolean;
}

const getHeaders = (initialHeaders: HeadersInit, json: boolean) => {
  const headers: HeadersInit = {};

  if (json) {
    headers['Content-Type'] = 'application/json';
  }

  return { ...headers, ...initialHeaders };
};

export const postMutation = <TData = unknown, TVariables = unknown>({
  endpoint,
  method = 'POST',
  headers = {},
  requestOptions = {},
  mutationOptions = {},
  json = true,
}: MutationArguments<TData, TVariables>) => (
    useMutation<TData, FetchError, TVariables>({
      mutationFn: (data: TVariables): Promise<TData> => (
        fetchData({
          endpoint,
          options: {
            method,
            headers: getHeaders(headers, json),
            ...{ body: json ? JSON.stringify(data) : data },
          },
          ...requestOptions,
        })
      ),
      ...mutationOptions,
    })
  );

export const getMutation = <TData = unknown, TVariables = unknown>({
  endpoint,
  headers = {},
  requestOptions,
  mutationOptions,
}: MutationArguments<TData, TVariables>) => (
    useMutation<TData, FetchError, TVariables>({
      mutationFn: (): Promise<TData> => (
        fetchData({
          endpoint,
          options: {
            method: 'GET',
            headers: getHeaders(headers, true),
          },
          ...requestOptions,
        })
      ),
      ...mutationOptions,
    })
  );

export const deleteMutation = <TData = unknown, TVariables = unknown>({
  endpoint,
  requestOptions,
  mutationOptions,
}: MutationArguments<TData, TVariables>) => (
    useMutation<TData, FetchError, TVariables>({
      mutationFn: () => (
        fetchData({
          endpoint,
          options: { method: 'DELETE' },
          ...requestOptions,
        })
      ),
      ...mutationOptions,
    })
  );
