/* eslint-disable no-nested-ternary */
import { useRef } from 'react';
import { Flex } from '@mantine/core';
import { CalendarDate, isSameDay, isToday } from '@internationalized/date';
import { useCalendarCell } from 'react-aria';
import { RangeCalendarState } from 'react-stately';

import classes from './DatePicker.module.css';

const CalendarCell = ({ state, date }: { state: RangeCalendarState; date: CalendarDate }) => {
  const ref = useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isFocused,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isDateToday = isToday(date, state.timeZone);
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;

  return (
    <div
      {...cellProps}
    >
      <Flex
        align="center"
        justify="center"
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={`
        ${classes.datePickerCell}
        ${isSelected || isFocused ? classes.selected : ''}
        ${isDisabled ? classes.disabled : ''}
        ${isDateToday && !isSelected ? classes.highlight : ''}
        ${!isSelected && !isDisabled ? classes.hovered : ''}
        ${
          // Darker selection background for the start and end.
          isSelectionStart || isSelectionEnd
            ? classes.selectionStartEnd
            : ''
          } ${
          // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? classes.selectionMiddle
              : ''
          }
        `}
      >
        {formattedDate}
      </Flex>
    </div>
  );
};

export default CalendarCell;
