import { useQueryClient } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import { useMercuryPages } from '@/hooks/useMercuryPages';
import { useUserId } from '@/hooks/useUserId';

// Types
import type { RoutePathDefinition } from '@/types/routes';
import classes from './Navbar.module.css';

interface ChildProps {
  child: RoutePathDefinition;
  sideBarExpanded: boolean;
  path: string;
  showTooltip?: boolean
}

const Child = ({
  child,
  sideBarExpanded,
  path,
  showTooltip,
}: ChildProps) => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const { getPageInfo } = useMercuryPages();
  const { hasAccess } = useMercuryPermissions();

  const page = child.getPageInfo && getPageInfo(child.page);
  const canAccess = page?.permissions ? hasAccess(page.permissions) : true;

  const { title, tooltip } = child.getPageInfo ? getPageInfo(child.page) : { title: child.title, tooltip: child.title };

  if (child.hideOnNoAccess && !canAccess) return null;

  const onMouseEnter = () => {
    if (child.prefetch && child.prefetch.length > 0) {
      child.prefetch.forEach(async (query) => {
        queryClient.prefetchQuery(query(userId));
      });
    }
  };

  return (
    <li onMouseEnter={onMouseEnter}>
      <NavLink
        to={path}
        className={({ isActive }) => `${classes.link} ${isActive ? classes.active : ''}`}
      >
        <Tooltip
          arrowOffset={10}
          arrowSize={4}
          label={tooltip}
          position="right"
          offset={40}
          color="var(--active-color)"
          disabled={!showTooltip || sideBarExpanded}
          visibleFrom="sm"
          transitionProps={{ duration: 0 }}
          withArrow
        >
          <span className={`${classes.linkIcon}`}>
            {child.icon && <child.icon />}
          </span>
        </Tooltip>
        <span className={classes.linkLabel}>{title}</span>
      </NavLink>
    </li>
  );
};

export default Child;
