import { useCallback, useRef } from 'react';
import {
  Box, Flex, Group, ScrollArea, Stack, UnstyledButton,
} from '@mantine/core';
import { type DateValue, type RangeCalendarProps, useLocale, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';

import PresetComp from './Preset';
import MonthDropdown from './MonthDropdown';
import YearDropdown from './YearDropdown';
import CalendarGrid from './CalendarGrid';

import { createCalendar } from './createCalendar';

import classes from './DatePicker.module.css';
import ChevronLeft from '@/assets/icons/content/chevron-left.svg';
import ChevronRight from '@/assets/icons/content/chevron-right.svg';

import type { Presets } from './types';

interface RangeCalerndarCompProps {
  rangeCalendarProps: RangeCalendarProps<DateValue>;
  presets?: Presets;
}

const RangeCalendar = ({ presets, rangeCalendarProps }: RangeCalerndarCompProps) => {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...rangeCalendarProps,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { calendarProps } = useRangeCalendar(rangeCalendarProps, state, ref);

  const setNewRange = useCallback((value: { start: DateValue, end: DateValue }) => {
    state.setValue(value);
  }, []);

  return (
    <Flex gap={5} classNames={{ root: classes.datePickerCalendarContainer }}>
      {presets && presets.length && (
      <ScrollArea.Autosize mah={300} mx="auto" scrollbarSize={2} classNames={{ root: classes.datePickerCustom }}>
        <Stack gap={0}>
          {presets.map((preset) => (
            <PresetComp key={preset.label || preset.value.end.toString()} preset={preset} setNewRange={setNewRange} />
          ))}
        </Stack>
      </ScrollArea.Autosize>
      )}

      <Box {...calendarProps} ref={ref} className={`${classes.datePickerCustom} ${classes.stretch}`}>
        <Group align="center" justify="space-between">
          <UnstyledButton onClick={state.focusPreviousPage}>
            <ChevronLeft />
          </UnstyledButton>
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
          <UnstyledButton onClick={state.focusNextPage}>
            <ChevronRight />
          </UnstyledButton>
        </Group>
        <CalendarGrid state={state} />
      </Box>
    </Flex>
  );
};

export default RangeCalendar;
