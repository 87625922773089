import { useMemo } from 'react';

import MultiSelect from '@/components/content/combobox/multiselect/MultiSelect';

import type { FilterProps, FilterValues } from '../types';
import type { Multiple } from '@/types/meta';

import { flatArrayToTree } from '@/utils/arrayToTreev2';

const getSelected = ({
  filter,
  filterMeta,
}: FilterValues<Multiple, any>) => {
  if (!filter) {
    return [];
  }

  if (typeof filter === 'number' || typeof filter === 'string') {
    const choice = filterMeta.choices.find((item) => String(item.value) === filter);
    return choice ? [{ value: choice.value, label: choice.label }] : [];
  }

  return filterMeta.choices
    .filter((item) => filter.indexOf(String(item.value)) !== -1)
    .map((choice) => ({ value: choice.value, label: choice.label }));
};

const MultiSelectFilter = ({ filterMeta, filter, setFilter }: FilterProps<Multiple, number[] | number | string>) => {
  const selectedItems = useMemo(() => getSelected({ filter, filterMeta }), [filter]);

  const items = useMemo(() => flatArrayToTree(filterMeta.choices as any), []);

  return (
    <MultiSelect
      items={items as any}
      value={selectedItems as any}
      setValue={(newValue) => {
        setFilter({ [filterMeta.name]: newValue.map((item) => item.value) });
      }}
    />
  );
};

export default MultiSelectFilter;
