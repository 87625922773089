import { useRiskIndicator } from '../riskindicators/useRiskIndicator';

import type { PropsWithChildren } from 'react';
import type { NormalizedRedmineField } from '../catalogs/types';

import DotIndicator from '@/components/content/dotindicator/DotIndicator';

export interface RelevantProps {
  industries: NormalizedRedmineField;
  locations: NormalizedRedmineField;
  technologies: NormalizedRedmineField;
}

const Relevant = ({ children, industries, locations, technologies }: PropsWithChildren<RelevantProps>) => {
  const {
    hasIndicator: hasRelevantIndustries,
  } = useRiskIndicator({ context: industries, indicator: 'relevant_industry_sectors' });
  const {
    hasIndicator: hasRelevantLocations,
  } = useRiskIndicator({ context: locations, indicator: 'relevant_locations' });
  const {
    hasIndicator: hasRelevantTechnologies,
  } = useRiskIndicator({ context: technologies, indicator: 'relevant_technologies' });

  const relevant = [
    { label: 'Industries', isRelevant: hasRelevantIndustries },
    { label: 'Locations', isRelevant: hasRelevantLocations },
    { label: 'Technologies', isRelevant: hasRelevantTechnologies },
  ];

  const relevantTooltip = relevant.reduce((accu, curr) => {
    const divider = accu ? ', ' : '';
    const text = curr.isRelevant ? `${accu}${divider}${curr.label}` : accu;

    return text;
  }, '');

  return (
    <DotIndicator display={!!relevantTooltip} tooltip={`This is relevant for you (${relevantTooltip})`} animated>
      {children}
    </DotIndicator>
  );
};

export default Relevant;
