/* eslint-disable react/no-array-index-key */
import { Flex } from '@mantine/core';
import { getWeeksInMonth } from '@internationalized/date';
import { useCalendarGrid, useLocale } from 'react-aria';
import type { RangeCalendarState } from 'react-stately';

import CalendarCell from './CalendarCell';

import classes from './DatePicker.module.css';

const CalendarGrid = ({ state }: { state: RangeCalendarState }) => {
  const { locale } = useLocale();
  const { gridProps, weekDays } = useCalendarGrid({}, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <Flex direction="column" {...gridProps} className={classes.datePickerCalendarGrid}>
      <div className={classes.datePickerGrid}>
        {weekDays.map((day, i) => (
          <Flex
            align="center"
            justify="center"
            key={`${day}-${i}`}
            className={`${classes.datePickerCell} ${classes.highlight}`}
          >
            {day}
          </Flex>
        ))}
      </div>
      <div className={classes.datePickerWeeks}>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <div key={`week-${weekIndex}`} className={classes.datePickerGrid}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) => (
                date
                  ? <CalendarCell key={`${weekIndex}-${date.day}`} state={state} date={date} />
                  : <div key={`${weekIndex}-day-${i}-disabled`} />
              ))}
          </div>
        ))}
      </div>
    </Flex>
  );
};

export default CalendarGrid;
