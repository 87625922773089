import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';

// Context
import { MercuryPagesProvider } from './context/MercuryPagesContext';
import { MercuryPermissionsProvider } from './context/MercuryPermissions';
import { ToastContextProvider } from './context/ToastContext';
import { ToastContainer } from './components/content/toast/ToastContainer';
import { ConfirmationProvider } from './components/content/confirmationdialog/ConfirmationContext';
import { SessionContextProvider } from './context/SessionContext';
import { DrawerProvider } from './context/DrawerContext';
import RateLimit from './client/features/ratelimit/RateLimit';

import { queryClient as qc } from './lib/react-query/client';
import { PermanentMessageProvider } from './context/PermanentMessageContext';
import { PermanentMessage } from './components/content/permanentmessage/PermanentMessage';
import { mantineTheme } from './theme';
import { resolver } from './theme/resolver';
import { colorSchemeManager } from './theme/colorScheme';

const App = ({
  children,
}: PropsWithChildren) => {
  const [queryClient] = useState(qc());

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={mantineTheme}
        cssVariablesResolver={resolver}
        colorSchemeManager={colorSchemeManager}
        defaultColorScheme="auto"
      >
        <MercuryPagesProvider>
          <MercuryPermissionsProvider>
            <ConfirmationProvider>
              <ToastContextProvider>
                <DrawerProvider>
                  <PermanentMessageProvider>
                    <SessionContextProvider>
                      {children}
                      <ToastContainer />
                      <PermanentMessage />
                      <RateLimit />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </SessionContextProvider>
                  </PermanentMessageProvider>
                </DrawerProvider>
              </ToastContextProvider>
            </ConfirmationProvider>
          </MercuryPermissionsProvider>
        </MercuryPagesProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
