import { object as ST, string, optional } from 'superstruct';
import { fileList, required, requiredDependsOn } from '@/utils/inputValidation';

// Hooks
import { usePostSupport, useUnifiedApiPostSupport } from './services/postSupport';

// Types
import type { OnSubmitFunc } from '@/types/form';
import { SUPPORT_TYPES, type SupportFormFields, type SupportResponse, type SupportType } from './types';
import type { FetchError } from '@/types/api';

interface UseSupportFormProps {
  onSuccess?: (data: SupportResponse) => void;
  onError?: (error: FetchError) => void;
  type?: SupportType;
  relationship?: string;
}

export const useSupportForm = ({
  onSuccess,
  onError,
  type,
  relationship,
}: UseSupportFormProps) => {
  const { isPending: isPostingRfi, mutate: sendEmail } = usePostSupport();
  const { isPending: isPostingUnifiedApiRfi, mutate: sendUnifiedApiEmail } = useUnifiedApiPostSupport(type);

  const onSubmitUnifiedApi: OnSubmitFunc<SupportFormFields> = (rawFormData, setGeneralError, setSuccessMessage) => {
    const formData = new FormData();

    formData.append('request', JSON.stringify({
      name: rawFormData.subject,
      description: rawFormData.description,
      due_at: rawFormData.due_date,
      ...(relationship ? { [relationship]: [+rawFormData.ticket_id] } : {}),
      ...(rawFormData.subclient_id ? { clients: [+rawFormData.subclient_id] } : {}),
    }));

    Object.entries(rawFormData).forEach(([key, value]) => {
      if (value && key === 'attachment') {
        [...rawFormData.attachment].forEach(((att) => { formData.append('file', att); }));
      }
    });

    sendUnifiedApiEmail(
      formData,
      {
        onSuccess: (data) => {
          setSuccessMessage('Request sent successfully');
          onSuccess?.(data);
        },
        onError: (error) => {
          setGeneralError(error.message);
          onError?.(error);
        },
      },
    );
  };

  const schema = ST({
    subject: required(string(), 'Subject is required'),
    otherSubject: requiredDependsOn('subject', 'Other'),
    description: required(string(), 'Description is required'),
    due_date: required(string(), 'Due Date is required'),
    attachment: optional(fileList()),
    to: required(string(), ''),
    ticket_id: optional(string()),
    subclient_id: optional(string()),
  });

  return {
    isPostingRfi: type === SUPPORT_TYPES.SUPPORT ? isPostingRfi : isPostingUnifiedApiRfi,
    schema,
    onSubmit: onSubmitUnifiedApi,
    sendEmail,
  };
};
