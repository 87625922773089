// Components
import Title from '@/components/content/title/Title';
import { Text, TypographyStylesProvider } from '@mantine/core';

// Misc
import { sanitize } from '@/utils/sanitize';

interface NewsItemProps {
  date?: string;
  title: string;
  content: string;
}

const News = ({ newsItem }: { newsItem: NewsItemProps }) => (
  <div>
    <Title order={3} size="h4">
      <span>{newsItem.title}</span>
      {newsItem.date && <Text size="xs">{newsItem.date}</Text>}
    </Title>

    <TypographyStylesProvider
      mt={10}
      dangerouslySetInnerHTML={{
        __html: sanitize(newsItem.content, {
          ALLOWED_URI_REGEXP: /^(?:(?:mailto):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
        }),
      }}
    />
  </div>
);

export default News;
