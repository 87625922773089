import {
  PropsWithChildren, createContext, useContext, useMemo, useRef, useState,
} from 'react';

interface RateLimitProps {
  isRetryPossible: boolean;
  retryAfter: number;
  limitEnabled: boolean;
  enableRateLimit: (retryAfter: number) => void;
  disableRateLimit: VoidFunction;
  clearLimit: VoidFunction;
}

const RateLimit = createContext<RateLimitProps | null>(null);

export const RateLimitProvider = ({ children }: PropsWithChildren<{}>) => {
  const [retryAfter, setRetryAfter] = useState(0);
  const [limitEnabled, setLimitEnabled] = useState(false);
  const retryTimeoutRef = useRef<number | null>(null);

  const enableRateLimit = (retryAfter: number) => {
    // Store the retryAfter value in the state as milliseconds
    const inMs = retryAfter * 1000;
    setRetryAfter(inMs);
    setLimitEnabled(true);

    retryTimeoutRef.current = window.setTimeout(() => setRetryAfter(0), inMs);
  };

  const clearLimit = () => {
    if (retryTimeoutRef.current) {
      clearTimeout(retryTimeoutRef.current);
    }
  };

  const disableRateLimit = () => {
    setRetryAfter(0);
    setLimitEnabled(false);
    clearLimit();
  };

  const value = useMemo(() => ({
    enableRateLimit,
    disableRateLimit,
    limitEnabled,
    isRetryPossible: retryAfter <= 0,
    retryAfter,
    clearLimit,
  }), [retryAfter]);

  return (
    <RateLimit.Provider value={value}>
      {children}
    </RateLimit.Provider>
  );
};

export const useRateLimit = () => {
  const context = useContext(RateLimit);

  if (!context) {
    throw new Error('useRateLimit must be used within a RateLimitProvider');
  }

  return context;
};
