// Styles & Assets
import { Flex, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import Moon from '@/assets/icons/content/moon.svg';

const ThemeSwitch = () => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

  const themeToSwitch = computedColorScheme === 'light' ? 'dark' : 'light';

  const toggleTheme = () => {
    setColorScheme(themeToSwitch);
  };

  return (
    <Flex
      component="button"
      type="button"
      aria-label={`Switch to ${themeToSwitch} mode`}
      onClick={toggleTheme}
      id="theme-switch"
    >
      <Moon />
    </Flex>
  );
};

export default ThemeSwitch;
