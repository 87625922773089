import { Children, ReactNode, useState } from 'react';
import {
  Combobox, Group, InputBase, CheckIcon, Tabs, TabsProps, useCombobox, TabsListProps,
  Tooltip,
} from '@mantine/core';

import useUrlState from '@/hooks/useUrlStore';

import ChevronDown from '@/assets/icons/content/chevron-down.svg';

import classes from './Tabs.module.css';

interface Tab {
  tab: ReactNode;
  val: string;
  disabled?: boolean;
  tooltip?: string;
}

interface MercuryTabsProps extends TabsProps {
  tabs: Array<Tab>;
  id?: string;
  namespace?: string;
  small?: boolean;
  defaultValue?: string;
  saveTabInUrl?: boolean;
  urlObject?: Record<string, any>;
  sticky?: boolean;
  renderSelect?: boolean;
  grow?: boolean;
  justify?: TabsListProps['justify'];
}

const MercuryTabs = ({
  tabs,
  id,
  namespace,
  small,
  children,
  defaultValue: definedDefaultValue,
  saveTabInUrl,
  sticky,
  renderSelect,
  urlObject,
  grow,
  justify,
  ...tabsProps
}: MercuryTabsProps) => {
  const [state, actions] = useUrlState({} as any, {
    ...(id ? { namespace } : {}),
  });

  const { tab: currentTab } = state;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === 'keyboard') {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex('active');
      }
    },
  });

  const defaultValue = () => {
    if (currentTab && tabs.find(({ val }) => val === currentTab)) {
      return currentTab;
    }

    return definedDefaultValue || tabs[0].val;
  };

  const [activeTabState, setActiveTab] = useState<string | null>(defaultValue());
  const activeTab = saveTabInUrl ? currentTab ?? activeTabState : activeTabState;
  const selectedOption = tabs.find(({ val }) => val === activeTab);

  const onTabChange = (tab: string | null) => {
    if (saveTabInUrl && tab) {
      actions.set({ tab, ...(urlObject ?? {}) });
    } else {
      setActiveTab(tab);
    }

    if (renderSelect) {
      combobox.closeDropdown();
    }
  };

  const options = tabs.map(({ val, tab, disabled }) => (
    <Combobox.Option value={val} key={val} disabled={disabled} active={val === activeTab}>
      <Group gap="xs">
        {val === activeTab && <CheckIcon size={12} />}
        {tab}
      </Group>
    </Combobox.Option>
  ));

  return (
    <Tabs
      classNames={{
        root: classes.root,
        tab: `${classes.tab} ${small ? classes.small : ''}`,
        list: `${classes.list} ${sticky ? classes.sticky : ''}`,
        panel: classes.panel,
      }}
      id={`tabs-${id}`}
      keepMounted={false}
      onChange={onTabChange}
      value={activeTab}
      {...tabsProps}
    >
      {renderSelect ? (
        <Combobox
          store={combobox}
          data-testid={`tabs-select-${id}`}
          onOptionSubmit={onTabChange}
          withinPortal={false}
        >
          <Combobox.Target>
            <InputBase
              component="button"
              type="button"
              rightSection={<ChevronDown />}
              classNames={{ section: classes.selectSection }}
              onClick={() => combobox.toggleDropdown()}
              rightSectionPointerEvents="none"
              pointer
              multiline
            >
              {selectedOption?.tab}
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>{options}</Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      ) : (
        <Tabs.List grow={grow} justify={justify} data-testid={`tabs-list-${id}`}>
          {tabs.map(({ val, tab, tooltip, disabled }) => (
            <Tooltip label={tooltip} disabled={!tooltip} key={`tab-nav-${val}}`}>
              <Tabs.Tab value={val} disabled={disabled}>
                {tab}
              </Tabs.Tab>
            </Tooltip>
          ))}
        </Tabs.List>
      )}

      {Children.map(children, (child, index) => (
        <Tabs.Panel value={tabs[index].val} key={`tab-content-${tabs[index]}`}>{child}</Tabs.Panel>
      ))}
    </Tabs>
  );
};

export default MercuryTabs;
