import { useContext } from 'react';

import { MercuryPermissions } from '../context/MercuryPermissions';

export const useMercuryPermissions = () => {
  const mercuryContext = useContext(MercuryPermissions);

  if (!mercuryContext) {
    throw new Error('MercuryPermissions is not initialized. This hook must be used inside a Provider');
  }

  return mercuryContext;
};
