import { useContext } from 'react';

import { PermanentMessageContext } from '@/context/PermanentMessageContext';

export const usePermanentMessage = () => {
  const permanentMessageContext = useContext(PermanentMessageContext);

  if (!permanentMessageContext) {
    throw new Error(
      'PermanentMessageContext is not initialized. This hook must be used inside a PermanentMessageContextProvider',
    );
  }

  return permanentMessageContext;
};
