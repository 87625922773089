/* eslint-disable */
export const debounce = (callback: (e: any) => void, inmediateCb: any, wait: number = 300) => {
  let timeoutId: any = null;

  return (...args: any) => {
    window.clearTimeout(timeoutId);
    if (inmediateCb) inmediateCb.apply(null, args);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};
