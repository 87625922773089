import { createContext, useMemo, PropsWithChildren } from 'react';

import { mercuryPages } from '../constants/mercuryPages';

export const MercuryPagesContext = createContext<any>(null);

export const MercuryPagesProvider = ({ children }: PropsWithChildren<{}>) => {
  const getPageInfo = (title: string) => {
    const page = mercuryPages[title];

    return {
      ...page,
      tooltip: page.title,
    };
  };

  const value = useMemo(() => (
    { getPageInfo }
  ), []);

  return (
    <MercuryPagesContext.Provider value={value}>
      {children}
    </MercuryPagesContext.Provider>
  );
};
