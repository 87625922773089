import type { SubscriptionsTrials } from '@/types/mercury-data-types';

const getNonExpiredTrials = (trials: Array<SubscriptionsTrials>) => trials.filter((trial) => !trial.is_expired);
const getTrialsNoticeStorage = () => localStorage.getItem('trials.notice');

export const hasTrials = (trials: Array<SubscriptionsTrials>) => {
  const nonExpiredTrials = getNonExpiredTrials(trials);
  const forceHide = localStorage.getItem('isPlaywright');
  const trialsNoticeStorage = getTrialsNoticeStorage();

  if (forceHide) return false;

  if (!nonExpiredTrials.length) {
    if (trialsNoticeStorage) {
      localStorage.removeItem('trials.notice');
    }

    return false;
  }

  if (!trialsNoticeStorage) return true;

  const parsed = JSON.parse(trialsNoticeStorage);
  const hasTrialsWithoutNotice = nonExpiredTrials.filter((trial) => parsed[trial.name] !== trial.end_at);

  if (hasTrialsWithoutNotice) {
    const inNotice = Object.entries(parsed).filter(([name, end_at]) => (
      nonExpiredTrials.some((trial) => trial.name === name && trial.end_at === end_at)
    ));

    if (inNotice.length !== Object.keys(parsed).length) {
      localStorage.setItem('trials.notice', JSON.stringify(Object.fromEntries(inNotice)));
      return true;
    }
  }

  return hasTrialsWithoutNotice.length > 0;
};

export const getTrials = (trials: Array<SubscriptionsTrials>) => {
  const trialsNoticeStorage = getTrialsNoticeStorage();
  const nonExpiredTrials = getNonExpiredTrials(trials);

  if (!trialsNoticeStorage) {
    return nonExpiredTrials;
  }

  const parsed = JSON.parse(trialsNoticeStorage);
  return nonExpiredTrials.filter((trial) => !parsed[trial.name]);
};

export const setTrials = (trials: Array<SubscriptionsTrials>) => {
  const trialsNoticeStorage = getTrialsNoticeStorage();
  const parsed = trialsNoticeStorage ? JSON.parse(trialsNoticeStorage) : {};
  const nonExpiredTrials = getNonExpiredTrials(trials);

  nonExpiredTrials.forEach((trial) => {
    parsed[trial.name] = trial.end_at;
  });

  localStorage.setItem('trials.notice', JSON.stringify(parsed));
};
