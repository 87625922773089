import DOMPurify from 'dompurify';

const checkForFootnote = (node: Element) => (
  node.id.startsWith('user-content') || node.classList.contains('data-footnote-backref')
);

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node && !checkForFootnote(node)) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export const { sanitize } = DOMPurify;
