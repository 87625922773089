import { createContext, PropsWithChildren, useMemo, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';

import type { DialogConfig } from './types';

export const ConfirmationContext = createContext<any>(null);

export const ConfirmationProvider = ({ children }: PropsWithChildren<{}>) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({} as DialogConfig);

  const openDialog = (config: DialogConfig) => {
    setDialogOpen(true);
    setDialogConfig(config);
  };

  const resetDialog = () => {
    setDialogOpen(false);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig?.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig?.actionCallback(false);
  };

  const value = useMemo(() => ({ openDialog }), []);

  return (
    <ConfirmationContext.Provider value={value}>
      <ConfirmationDialog
        open={dialogOpen}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        {...dialogConfig}
      />
      {children}
    </ConfirmationContext.Provider>
  );
};
