import {
  type ReactNode, type ElementType, type ComponentPropsWithRef, forwardRef, type Ref,
} from 'react';

// Define the BaseInputProps to support custom props
type BaseInputProps<T extends ElementType> = {
  element: T;
  error?: ReactNode;
  displayError?: boolean;
  required?: boolean;
} & ComponentPropsWithRef<T>;

const BaseInput = forwardRef(
  <T extends ElementType>(
    {
      element: Element,
      error,
      displayError,
      required,
      ...props
    }: BaseInputProps<T>,
    ref: Ref<T>, // Ref to be forwarded
  ) => {
    const errorValue = () => {
      if (displayError) {
        return error;
      }

      return error ? true : null;
    };

    return (
      <Element
        aria-required={required}
        withAsterisk={required}
        inputWrapperOrder={['label', 'error', 'input', 'description']}
        error={errorValue()}
        ref={ref}
        {...props}
      />
    );
  },
);

export default BaseInput;
