/* eslint-disable react/no-array-index-key */
import { Box, Group } from '@mantine/core';

import ProfileMenu from './ProfileMenu';
import ThemeSwitch from './ThemeSwitch';
import SearchBar from '@/components/layout/nav/topbar/searchbar';

import type { LayoutProps } from '@/types/general';

import classes from './Topbar.module.css';

type TopBarProps = {
  topbarItems: LayoutProps['topbarItems'];
  includeSearch?: LayoutProps['includeSearch'];
  scrolled: boolean;
};

const TopBar = ({ topbarItems, includeSearch, scrolled }: TopBarProps) => (
  <div className={`${classes.barWrapper} ${!includeSearch ? classes.noSearch : ''}`}>
    {includeSearch && <SearchBar />}

    <Group component="ul" gap={10} classNames={{ root: `${classes.barNavList} ${scrolled ? classes.scrolled : ''}` }}>
      {topbarItems && topbarItems.length > 0 && topbarItems.map(({ item, mobileHidden }, i) => (
        <Box component="li" visibleFrom={`${mobileHidden ? 'sm' : ''}`} key={`topbaritem-${i}`}>{item}</Box>
      ))}
      <li>
        <ProfileMenu />
      </li>
      <li>
        <ThemeSwitch />
      </li>
    </Group>
  </div>
);

export default TopBar;
