import type { ReactNode } from 'react';

// Components
import SubMenuChild from './SubMenuChild';

// Routes
import { mapRouteswithChildren } from './mapRouteswithChildren';

// Types
import type { RoutePathDefinition } from '@/types/routes';

interface MapRoutesArgs {
  routes: RoutePathDefinition[];
  sideBarExpanded: boolean;
  parent?: string;
  hideLabel?: boolean;
  showTooltip?: boolean;
}

const mapChildren = ({
  routes,
  sideBarExpanded,
  hideLabel = true,
  showTooltip = true,
  parent = '',
}: MapRoutesArgs): ReactNode => routes.map((child) => {
  if (!child.nav && !child.children) {
    return undefined;
  }

  if (child.isSubMenu) {
    return (
      <SubMenuChild key={`submenu-${child.path}`} item={child} sideBarExpanded={sideBarExpanded} />
    );
  }

  return mapRouteswithChildren({ child, sideBarExpanded, hideLabel, showTooltip, parent });
});

export const mapRoutesToMenu = ({ routes, sideBarExpanded }: MapRoutesArgs) => (
  <ul>
    {mapChildren({ routes, sideBarExpanded })}
  </ul>
);
