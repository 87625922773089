import { useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';
import { QueryOptions } from '@/types/react-query';
import { FetchError } from '@/types/api';

export interface PingResponse {
  body: 'pong';
}

export const ping = (queryOptions: QueryOptions<PingResponse>) => useQuery<PingResponse, FetchError>({
  queryKey: ['ping'],
  queryFn: () => fetchData({ endpoint: 'mercury/ping' }),
  meta: { avoidAuthRedirection: true },
  ...queryOptions,
});
