import { useSession } from '@/hooks/useSession';
import { useLogout } from '../services/logout';

const Logout = () => {
  const {
    mutation: { mutate: serverLogout, isPending: isLoginOut },
    clientLogout,
  } = useLogout();
  const { stopSession } = useSession();

  const onClick = () => {
    serverLogout('');
    stopSession();
    clientLogout();
  };

  return (
    <button type="button" id="logout" onClick={onClick} disabled={isLoginOut}>
      Logout
    </button>
  );
};

export default Logout;
