import { postMutation } from '@/lib/react-query/mutate';

import { useAuth } from '@/hooks/useAuth';
import { useQueryActions } from '@/hooks/useQueryActions';

import { dashboardKeys } from '../../dashboard/services/keys';
import { rfiKeys } from '../../servicerequests/services/keys';
import type { SupportResponse, SupportType } from '../types';

export const usePostSupport = () => {
  const { userInfo: { user_id: userId } } = useAuth();
  const { invalidateQueries } = useQueryActions();

  return postMutation<SupportResponse>({
    endpoint: `mercury/rfi/${userId}`,
    json: false,
    requestOptions: {
      error: 'There was a problem with the request, please try again',
      contentType: 'multipart/form-data; boundary=----requestforinformation',
    },
    mutationOptions: {
      onSuccess: () => {
        invalidateQueries([
          dashboardKeys.rfi(),
          rfiKeys.table(),
          rfiKeys.gfx(),
        ]);
      },
    },
  });
};

export const useUnifiedApiPostSupport = (type?: SupportType) => {
  const { invalidateQueries } = useQueryActions();

  return postMutation<SupportResponse>({
    endpoint: type === 'intel' || type === 'takedown' ? 'v0/issue/rfis/' : 'v0/issue/support/',
    json: false,
    requestOptions: {
      error: 'There was a problem with the request, please try again',
      contentType: 'multipart/form-data; boundary=----requestforinformation',
    },
    mutationOptions: {
      onSuccess: () => {
        invalidateQueries([
          dashboardKeys.rfi(),
          rfiKeys.table(),
          rfiKeys.gfx(),
        ]);
      },
    },
  });
};
