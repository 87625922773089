// Notifications
export type Notification = {
  total: number;
  unread: number;
  unacknowledged: number;
};

export enum NotificationsType {
  INTEL_FINISHED = 'intel_finished',
  INTEL_RAW = 'intel_raw',
  VULNERABILITIES = 'vulnerabilities',
  DRP_ALERTS = 'drp_alerts',
  DRP_TAKEDOWNS = 'drp_takedowns',
  RFI = 'rfi',
}

export type NotificationRecord = Record<NotificationsType, Notification>;
