import { RangeValue } from '@/components/content/datepicker/types';
import { parseDate } from '@internationalized/date';

export const parseRangeDate = (date: string): RangeValue | null => {
  if (!date) return null;

  const [start, end] = date.split('/').map((d) => parseDate(d));

  return { start, end };
};
