import { NotificationsType } from '@/client/features/notifications/types';
import type { IPages } from '../types/general';
import { CATALOGS, MODULES } from './permissions';

export const MercuryTrackersIds = {
  finished: 1,
  raw: 4,
  alerts: 13,
  takedowns: 12,
  vulnerabilities: 'vulnerabilities',
  rfi: 5,
  support: 'support',
  threatactors: 'threatactors',
  malwarekits: 'malwarekits',
  providers: 'providers',
  personas: 'personas',
  vulnerabilitiesCat: 'vulnerabilitiesCat',
  attcks: 'attcks',
  sources: 'sources',
} as const;

export type TMercuryTrackersIds = typeof MercuryTrackersIds[keyof typeof MercuryTrackersIds];

export type TMercuryTrackersAccesor = typeof MercuryTrackersAccesor[keyof typeof MercuryTrackersAccesor];

export const MercuryTrackersAccesor = {
  finished: 'finished',
  raw: 'raw',
  alerts: 'alerts',
  takedowns: 'takedowns',
  vulnerabilities: 'vulnerabilities',
  rfi: 'rfi',
} as const;

export const MercuryTrackersKeys = {
  finished: 'finished',
  raw: 'raw',
  alerts: 'alerts',
  takedowns: 'takedowns',
  vulnerabilities: 'alerts',
  rfi: 'rfi',
} as const;

export type Tracker = {
  notification?: NotificationsType,
  tracker_name: string,
  page: string | null;
  key?: string;
  title: string;
  parent: string;
  type: 'issue' | 'entity';
};

export const mercuryTrackers: { [key: number | string]: Tracker } = {
  1: {
    notification: NotificationsType.INTEL_FINISHED,
    tracker_name: 'Finished Intelligence',
    page: 'finished',
    key: MercuryTrackersKeys.finished,
    title: 'Finished Intelligence',
    parent: 'intelligence',
    type: 'issue',
  },
  4: {
    notification: NotificationsType.INTEL_RAW,
    page: 'raw',
    key: MercuryTrackersKeys.raw,
    tracker_name: 'Raw Intelligence',
    title: 'Raw Intelligence',
    parent: 'intelligence',
    type: 'issue',
  },
  13: {
    notification: NotificationsType.DRP_ALERTS,
    page: 'alert',
    key: MercuryTrackersKeys.alerts,
    tracker_name: 'DRP Alerts',
    title: 'Incidents',
    parent: 'incidentmanagement',
    type: 'issue',
  },
  12: {
    notification: NotificationsType.DRP_TAKEDOWNS,
    page: 'takedown',
    key: MercuryTrackersKeys.takedowns,
    tracker_name: 'DRP Takedowns',
    title: 'Takedowns',
    parent: 'incidentmanagement',
    type: 'issue',
  },
  vulnerabilities: {
    notification: NotificationsType.VULNERABILITIES,
    page: 'alert',
    key: MercuryTrackersKeys.vulnerabilities,
    tracker_name: 'Vulnerabilities',
    title: 'Vulnerabilities',
    parent: 'vulnerabilities',
    type: 'issue',
  },
  5: {
    notification: NotificationsType.RFI,
    tracker_name: 'RFI',
    page: 'alert',
    key: MercuryTrackersKeys.rfi,
    title: 'Service Requests',
    parent: 'servicerequests',
    type: 'issue',
  },
  support: {
    notification: NotificationsType.RFI,
    tracker_name: 'RFI',
    page: 'support',
    key: MercuryTrackersKeys.rfi,
    title: 'Service Requests',
    parent: 'servicerequests',
    type: 'issue',
  },
  threatactors: {
    tracker_name: 'Threat Actors',
    page: 'threatactors',
    title: 'Threat Actors',
    parent: 'catalogs',
    type: 'entity',
  },
  malwarekits: {
    tracker_name: 'Malware Kits',
    page: 'malwarekits',
    title: 'Malware Kits',
    parent: 'catalogs',
    type: 'entity',
  },
  providers: {
    tracker_name: 'Providers',
    page: 'providers',
    title: 'Providers',
    parent: 'catalogs',
    type: 'entity',
  },
  personas: {
    tracker_name: 'Personas',
    page: 'personas',
    title: 'Personas',
    parent: 'catalogs',
    type: 'entity',
  },
  vulnerabilitiesCat: {
    tracker_name: 'Vulnerabilities',
    page: 'vulnerabilities',
    title: 'Vulnerabilities',
    parent: 'catalogs',
    type: 'entity',
  },
  attcks: {
    tracker_name: 'ATT&CK',
    page: 'attck',
    title: 'ATT&CK',
    parent: 'catalogs',
    type: 'entity',
  },
  sources: {
    tracker_name: 'Sources',
    page: 'sources',
    title: 'Sources',
    parent: 'catalogs',
    type: 'entity',
  },

} as const;

export const mercuryPages: IPages = {
  intelligence: {
    id: 'intelligence',
    title: 'Intelligence',
    permissions: MODULES.intelligence,
  },
  supplychain: {
    id: 'supplychain',
    title: 'Supply Chain Panel',
    permissions: MODULES.supplychain,
  },
  drp: {
    id: 'drp',
    title: 'Brand Protection',
  },
  incidentmanagement: {
    id: 'incidentmanagement',
    title: 'Incident Management',
  },
  vulnerabilitiesInt: {
    id: 'vulnerabilitiesInt',
    title: 'Vulnerabilities Intelligence',
  },
  servicerequests: {
    id: 'servicerequests',
    title: 'Service Requests',
  },
  alertsmanagement: {
    id: 'alertsmanagement',
    title: 'Alerts Management',
    permissions: MODULES.alertsmanagement,
  },
  identitypanel: {
    id: 'identitypanel',
    title: 'Identity Panel',
  },
  threatactors: {
    id: 'threatactors',
    title: 'Threat Actors',
    permissions: CATALOGS.threatactors,
  },
  malwarekits: {
    id: 'malwarekits',
    title: 'Malware Kits',
    permissions: CATALOGS.malwarekits,
  },
  attcks: {
    id: 'attcks',
    title: 'ATT&CK',
    permissions: CATALOGS.attcks,
  },
  sources: {
    id: 'sources',
    title: 'Sources',
    permissions: CATALOGS.sources,
  },
  providers: {
    id: 'providers',
    title: 'Providers',
    permissions: CATALOGS.providers,
  },
  personas: {
    id: 'personas',
    title: 'Personas',
    permissions: CATALOGS.personas,
  },
  vulnerabilitiesCat: {
    id: 'vulnerabilitiesCat',
    title: 'Vulnerabilities Catalog',
    permissions: CATALOGS.vulnerabilities,
  },
  ransomware: {
    id: 'ransomware',
    title: 'Ransomware',
    permissions: MODULES.ransomware,
  },
  easm: {
    id: 'easm',
    title: 'Attack Surface',
    permissions: MODULES.easm,
  },
  analytics: {
    id: 'analytics',
    title: 'Analytics Dashboard',
    permissions: MODULES.analyticsdashboard,
  },
};
