import { useQuery } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import type { News } from '../types';

export const useGetNews = () => useQuery({
  queryKey: ['news'],
  queryFn: () => fetchData({ endpoint: 'docs/news.json' }),
  select: (data: Array<News>) => data.slice(0, 3),
});
