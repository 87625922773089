import { NativeSelect, type NativeSelectProps } from '@mantine/core';

import BaseInput from './BaseInput';

import type { BaseElement } from '@/types/form';
import type { FieldValues } from 'react-hook-form';

interface MercuryNativeSelectProps<TFormValues extends FieldValues> extends
  Omit<NativeSelectProps, 'name'>,
  BaseElement<TFormValues> { }

const MercuryNativeSelect = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  ...inputProps
}: MercuryNativeSelectProps<TFormValues>) => (
  <BaseInput
    {...register(name, formOptions)}
    {...inputProps}
    id={name}
    element={NativeSelect}
  />
  );

export default MercuryNativeSelect;
