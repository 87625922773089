import { ADMIN_URL, MERCURY_URL } from '@/constants/env';
import { isAdmin } from '@/utils/usersRole';

import type { UserInfo } from '../types';

export const useAdminRedirect = () => {
  const isInAdminDomain = () => {
    const { origin, pathname } = window.location;

    return origin === ADMIN_URL && pathname.includes('/admin');
  };

  const hasAdminRedirect = (user: UserInfo) => isAdmin(user.role) && !isInAdminDomain();

  const redirectAdmin = () => {
    window.location.replace(`${ADMIN_URL}/admin`);
  };

  const returnToMercuryOrigin = (path: string) => {
    window.location.replace(`${MERCURY_URL}/${path}`);
  };

  return {
    hasAdminRedirect,
    redirectAdmin,
    returnToMercuryOrigin,
    isInAdminDomain,
  };
};
