import { useRef, useEffect, useMemo } from 'react';
import QRCode, { QRCodeRenderersOptions } from 'qrcode';

interface IQRCode {
  text: string;
  options: QRCodeRenderersOptions;
}

export const useQRCode = () => {
  const CanvasComponent = <T extends HTMLCanvasElement>({
    text,
    options,
  }: IQRCode) => {
    const canvasRef = useRef<T>(null);

    useEffect(() => {
      if (canvasRef && canvasRef.current) {
        QRCode.toCanvas(
          canvasRef.current,
          text,
          options,
          (error) => {
            if (error) {
              throw error;
            }
          },
        );
      }
    }, [canvasRef]);

    return <canvas ref={canvasRef} />;
  };

  const Canvas = useMemo(() => CanvasComponent, []);

  return Canvas;
};
