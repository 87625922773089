import { Combobox, Group, InputBase, ScrollArea } from '@mantine/core';

import Option from './Option';

import { useMultiSelect } from './useMultiSelect';

import ChevronDown from '@/assets/icons/content/chevron-down.svg';

import type { MultiSelectProps } from './types';

import classes from './MultiSelect.module.css';

const MultiSelect = ({
  items,
  label,
  value,
  setValue,
  forceCustomText,
  removeSelectedItems,
}: MultiSelectProps) => {
  const {
    combobox,
    search,
    setSearch,
    handleValueSelect,
    handleValueRemove,
    isSelected,
    isDisabled,
    nothingFound,
    allSelected,
    mapSelectList,
  } = useMultiSelect({
    items,
    setValue,
    value,
    removeSelectedItems,
  });

  const values = value?.map((val) => (
    <Group
      align="center"
      component="li"
      className={classes.selected}
      gap={5}
      key={`value-${val.value}`}
    >
      {val.label}

      <button type="button" onClick={() => handleValueRemove(val.value)}>
        x
      </button>
    </Group>
  ));

  return (
    <div>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
        classNames={{
          dropdown: classes.dropdown,
        }}
      >
        <Combobox.DropdownTarget>
          <Combobox.EventsTarget>
            <InputBase
              value={search ?? ''}
              label={label}
              rightSectionPointerEvents="none"
              rightSection={<ChevronDown />}
              placeholder="Select one or more values"
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
              onChange={(event) => {
                combobox.updateSelectedOptionIndex();

                if (!allSelected) {
                  setSearch(event.currentTarget.value);
                }
              }}
              pointer
            />
          </Combobox.EventsTarget>
        </Combobox.DropdownTarget>

        {values && values.length > 0 && (
          <Group mt={15} wrap="wrap" gap={5} component="ul">
            {values}
          </Group>
        )}

        {combobox.dropdownOpened && (
          <Combobox.Dropdown>
            <Combobox.Options>
              <ScrollArea.Autosize type="scroll" mah={200}>
                {forceCustomText && <Combobox.Empty>{forceCustomText}</Combobox.Empty>}

                {nothingFound && !forceCustomText && (
                  <Combobox.Empty>Nothing found</Combobox.Empty>
                )}

                {removeSelectedItems && allSelected && !forceCustomText && (
                  <Combobox.Empty>All options selected</Combobox.Empty>
                )}

                {mapSelectList(([root, ...rest]) => (
                  <Option
                    forceExpand={search !== ''}
                    item={root}
                    subItems={rest}
                    isSelected={isSelected}
                    isDisabled={isDisabled}
                    handleValueSelect={handleValueSelect}
                    key={`option-${root.value}`}
                  />
                ))}
              </ScrollArea.Autosize>
            </Combobox.Options>
          </Combobox.Dropdown>
        )}
      </Combobox>
    </div>
  );
};

export default MultiSelect;
