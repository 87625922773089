import { type ReactNode, useCallback } from 'react';
import type { TableState } from '@tanstack/react-table';

// Components
import RouterLink from '@/components/content/link/RouterLink';
import Relevant, { type RelevantProps } from './Relevant';
import ConditionalWrapper from '@/components/content/conditionalwrapper/ConditionalWrapper';

// Types
import { Tracker } from '@/constants/mercuryPages';

interface TicketIDLinkProps {
  value: number;
  name?: string;
  tracker?: Tracker;
  useParent?: boolean;
  usePage?: boolean;
  tableState?: TableState;
  relevantData?: RelevantProps;
  onClick?: VoidFunction;
}

const IssueIDLinkv2 = ({
  value,
  name,
  tracker,
  useParent,
  usePage,
  tableState,
  relevantData,
  onClick,
}: TicketIDLinkProps) => {
  const relevantWrapper = useCallback((children: ReactNode) => {
    if (typeof relevantData !== 'undefined') {
      return <Relevant {...relevantData}>{children}</Relevant>;
    }

    return children;
  }, []);

  const state = tableState ? {
    start: tableState.pagination.pageIndex * tableState.pagination.pageSize,
    length: tableState.pagination.pageSize,
  } : null;

  const { parent, page, type } = tracker ?? {};

  // eslint-disable-next-line max-len
  const link = `${useParent && parent ? `/${parent}/` : ''}${(usePage || useParent) && page ? `${page}/` : ''}${type}/${value}`;

  return (
    <ConditionalWrapper
      condition={typeof relevantData !== 'undefined'}
      wrapper={relevantWrapper}
    >
      <RouterLink
        to={link}
        state={{ tableState: state }}
        onClick={onClick}
      >
        {name || value}
      </RouterLink>
    </ConditionalWrapper>
  );
};
export default IssueIDLinkv2;
