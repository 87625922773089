import { Checkbox, Combobox, Group } from '@mantine/core';

import type { Item, Value } from './types';

import classes from './MultiSelect.module.css';

interface OptionProps {
  item: Item;
  subItems?: Array<Item>;
  isSelected: (item: Value) => boolean;
  isDisabled: (item: Value) => boolean;
  forceExpand: boolean;
  handleValueSelect: (value: Value) => void;
}

const Option = ({
  item,
  isSelected,
  isDisabled,
  forceExpand,
  subItems,
  handleValueSelect,
}: OptionProps) => {
  const { label, value, depth = 0, children } = item;
  const isRoot = typeof subItems !== 'undefined' && subItems.length > 0;

  const selected = isSelected(value);
  const disabled = isDisabled(value);

  const onSelectRoot = () => handleValueSelect(value);

  const padding = () => {
    if (depth > 1) {
      return (depth * 5) + 65;
    }

    if (isRoot) {
      return 0;
    }

    if (!isRoot && !depth) {
      return 15;
    }

    return 65;
  };

  return (
    <>
      <Group justify="space-between" align="center">
        {isRoot && (
          <Checkbox
            checked={selected}
            onChange={onSelectRoot}
            classNames={{ root: classes.rootCheckbox }}
          />
        )}

        <Combobox.Option
          classNames={{
            option: `
            ${classes.option}
            ${isRoot ? classes.parent : ''}
            ${!isRoot && children && children.length > 0 ? classes.highlightLabel : ''}
            `,
          }}
          value={String(value)}
          key={`option-${value}`}
          pl={padding()}
          active={selected}
          disabled={disabled}
        >
          {label}
        </Combobox.Option>
      </Group>

      {subItems && subItems.map((child) => (
        <Option
          key={`child-${child.value}`}
          item={child}
          isSelected={isSelected}
          isDisabled={isDisabled}
          forceExpand={forceExpand}
          handleValueSelect={handleValueSelect}
        />
      ))}
    </>
  );
};

export default Option;
