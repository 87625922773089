import { postMutation } from '@/lib/react-query/mutate';

const sendrecovery = 'auth/send_recovery';

export const useSendPasswordRecoveryMutation = () => postMutation({
  endpoint: sendrecovery,
  requestOptions: {
    requestLimit:
        'Looks like you have already sent a password recovery less than 5 minutes ago. Please try again later.',
  },
  mutationOptions: {
    meta: {
      avoidAuthRedirection: true,
    },
  },
});
