import { useContext } from 'react';

import { SessionContext } from '@/context/SessionContext';

export const useSession = () => {
  const sessionContext = useContext(SessionContext);

  if (!sessionContext) {
    throw new Error('Session Context is not initialized. This hook must be used inside a SessionContextProvider');
  }

  return sessionContext;
};
