import { createContext, PropsWithChildren, useMemo, useState } from 'react';

import type { Message, MessageLevel } from '@/components/content/permanentmessage/types';

type PermanentMesageOptions = {
  timeout?: number;
};

type PermanentMessageValue = {
  message: Message | null;
  createMessage: (newMessage: string, level: MessageLevel, options?: PermanentMesageOptions) => void;
  removeMessage: VoidFunction;
};

export const PermanentMessageContext = createContext<PermanentMessageValue | null>(null);

export const PermanentMessageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [message, setMessage] = useState<Message | null>(null);

  const removeMessage = () => setMessage(null);

  const createMessage = (newMessage: string, level: MessageLevel, options: PermanentMesageOptions = {}) => {
    const { timeout } = options;
    const timeoutDef = timeout ? setTimeout(removeMessage, timeout) : null;

    setMessage({
      message: newMessage,
      level,
      clear: () => {
        if (timeoutDef) clearTimeout(timeoutDef);

        removeMessage();
      },
    });
  };

  const contextValue = useMemo(
    () => ({ message, removeMessage, createMessage }),
    [message, removeMessage, createMessage],
  );

  return (
    <PermanentMessageContext.Provider value={contextValue}>
      {children}
    </PermanentMessageContext.Provider>
  );
};
